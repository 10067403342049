import { WebsiteUrl } from "api/config";

export type QrTypes = "so" | "quote" | "po" | "unit" | "item" | "device" | "receive" | "traveler";

const basePaths = {
  so: WebsiteUrl + "panel/soDetail/",
  quote: WebsiteUrl + "panel/quoteDetail/",
  po: WebsiteUrl + "purchase-order",

  unit: WebsiteUrl + "product/",
  device: WebsiteUrl + "product/",

  item: WebsiteUrl + "item",
  receive: WebsiteUrl + "receive",
  traveler: WebsiteUrl + "traveler",
};

export class QR {
  type: QrTypes;

  constructor(type: QrTypes) {
    this.type = type;
  }

  public generate(data: any, path?: string) {
    const url = new URL(basePaths[this.type] + (path || ""));
    for (const [key, value] of Object.entries({ ...data, _qr_type: this.type })) {
      url.searchParams.append(key, String(value));
    }
    return url.toString();
  }

  public generateSoUrl(data: { soId: string }) {
    return this.generate(data, data.soId);
  }

  public generateQuoteUrl(data: { quoteId: string }) {
    return this.generate(data, data.quoteId);
  }

  public generatePoUrl(data: { poId: string }) {
    return this.generate(data);
  }

  public generateItemUrl(data: { panel: string; itemId: string; itemNo: string; itemName: string }) {
    return this.generate(data);
  }

  public generateUnitUrl(data: { unitId: string; serialNumber: string; soNumber: string; no: string }) {
    return this.generate(data);
  }

  public generateReceivingUrl(data: { id: string; no: string }) {
    return this.generate(data);
  }

  public extract(scanData: string) {
    try {
      const url = new URL(scanData);
      const entries = Array.from(url.searchParams.entries());
      const queries = Object.fromEntries(entries);
      return {
        url,
        type: queries._qr_type,
        queries,
      };
    } catch (error) {
      return;
    }
  }
}
