import React, { useState } from "react";
import { IconButton, Box, Divider } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import UPCToggle from "../../components/UPC";
import NotificationButton from "./Notification/Button";
import ProfileButton from "./Profile/Button";
import CheckIn from "./checkIn";
import { PhoneHeader } from "./phoneHeader";
import HelpMenu from "./Help/Menu";
import FAQ from "assets/icons/header/FAQ.svg";
import message from "assets/icons/header/Message.svg";

export const TopAppBar = ({
  isChatOpen,
  onOpenChatClicked,
}: {
  isChatOpen: boolean;
  onOpenChatClicked: () => void;
}) => {
  const phone = useMediaQuery("(max-width:900px)");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <>
      {anchorEl && <HelpMenu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />}
      <Box flex={1} display="flex" alignItems="center" sx={{ borderRadius: 0, padding: "0px !important" }}>
        {phone ? (
          <PhoneHeader isChatOpen={isChatOpen} onOpenChatClicked={onOpenChatClicked} />
        ) : (
          <>
            <div style={{ flexGrow: 1 }}></div>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CheckIn />
              <UPCToggle />
              {!isChatOpen && (
                <IconButton title="Chat" size="small" color="inherit" onClick={onOpenChatClicked}>
                  <img alt="chat" src={message} style={{ width: 28, height: 28 }} />
                </IconButton>
              )}
              <NotificationButton />
              <IconButton size="small" title="Help" color="inherit" onClick={handleOpenMenu}>
                <img alt="chat" src={FAQ} style={{ width: 28, height: 28 }} />
              </IconButton>
              <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 2 }} />
              <ProfileButton />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
