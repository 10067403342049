import React, { useEffect, useState } from "react";
import { Box, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { ChevronLeft, SettingsRounded } from "@mui/icons-material";
import { useLock } from "common/Lock";
import drawerBg from "assets/homeIcon/sidebarBg.png";
import home from "assets/homeIcon/home.svg";
import sales from "assets/homeIcon/sales.svg";
import accounting from "assets/homeIcon/accounting.svg";
import engineering from "assets/homeIcon/engineering.svg";
import fieldService from "assets/homeIcon/fieldService.svg";
import inventory from "assets/homeIcon/inventory.svg";
import production from "assets/homeIcon/production.svg";
import purchasing from "assets/homeIcon/purchasing.svg";
import shipping from "assets/homeIcon/shipping.svg";
import dspmLogo from "assets/homeIcon/dspmLogo.svg";
import phocus from "assets/homeIcon/phocus.svg";
import { useSession } from "features/Session/sessionsSlice";

const dstyle = {
  marginBottom: "4px",
  width: "210px",
};
const adstyle = {
  marginTop: "10px",
  marginBottom: "10px",
  width: "210px",
  backgroundColor: "#E68031",
  display: "flex",
  borderRadius: " 0 10px 10px 0",
  height: "40px",
  alignItems: "center",
  paddingTop: "4px",
};

const drawerItems = [
  {
    name: "Home",
    link: "/panel/home",
    icon: <img alt="homeIcon" src={home} style={{ width: "15px", height: "15px" }} />,
  },
  {
    name: "Sales",
    link: "/panel/sales",
    icon: <img alt="salesIcon" src={sales} />,
  },
  {
    name: "Purchasing",
    link: "/panel/purchase",
    icon: <img alt="purchasingIcon" src={purchasing} />,
  },
  {
    name: "Inventory",
    link: "/panel/inventory",
    icon: <img alt="inventory" src={inventory} />,
  },
  {
    name: "Engineering",
    link: "/panel/engineering",
    icon: <img alt="engineering" src={engineering} />,
  },
  {
    name: "Production",
    link: "/panel/production",
    icon: <img alt="production" src={production} />,
  },
  {
    name: "Shipping",
    link: "/panel/shipping/dashboard",
    icon: <img alt="shipping" src={shipping} />,
  },
  {
    name: "Accounting",
    link: "/panel/accounting",
    icon: <img alt="accounting" src={accounting} />,
  },
  {
    name: "Field Service",
    link: "/panel/fieldservice",
    icon: <img alt="fieldservice" src={fieldService} />,
  },

  {
    name: "Settings",
    link: "/panel/setting",
    icon: <SettingsRounded />,
  },
];

export default function DrawerContent({ onClose }: { onClose: () => void }) {
  const location = useLocation();
  const { setLock } = useLock();
  const user = useSession();
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    const roles = (user?.roleName || []) as string[];
    let items = drawerItems;

    // for (const role of roles) {
    //   if (role === "salesAdmin") {
    //     items.push(drawerItems[1]);
    //   } else if (role === "purchaseAdmin") {
    //     items.push(drawerItems[2]);
    //   } else if (role === "GOD") {
    //     items = drawerItems;
    //   }
    // }

    setItems(items);
  }, [user?.roleName]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100%"
      sx={{
        backgroundImage: `url(${drawerBg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box m={2} display="flex" alignItems="center">
        <img src={dspmLogo} alt="dspm" style={{ width: 100, height: "auto", marginRight: "auto", padding: 1 }} />
        <IconButton onClick={onClose} sx={{ color: "white" }}>
          <ChevronLeft />
        </IconButton>
      </Box>
      <Divider />
      <List style={{ marginBottom: "auto", paddingTop: "2px" }}>
        {items.map((item, i) => (
          <div style={location.pathname.includes(item.link) ? adstyle : dstyle} onClick={() => setLock(true)}>
            <Link key={i} to={item.link} style={{ textDecoration: "none", border: "none", outline: "none" }}>
              <ListItem
                style={{
                  padding: "3px 12px",
                  color: location.pathname.includes(item.link) ? "#fff" : "#848484",
                  fontWeight: location.pathname.includes(item.link) ? "bold" : "normal",
                }}
              >
                <ListItemIcon
                  style={{
                    fill: location.pathname.includes(item.link) ? "#fff" : "#8e8e8e",
                  }}
                  className={location.pathname.includes(item.link) ? "Active" : ""}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText> {item.name} </ListItemText>
              </ListItem>
            </Link>
            {location.pathname.includes(item.link) ? (
              <div
                style={{
                  backgroundColor: "#fff",
                  height: "15px",
                  width: "0px",
                  marginBottom: "auto",
                  marginTop: "auto",
                  border: "1px solid white",
                  marginLeft: "auto",
                  marginRight: "10px",
                  borderRadius: "30%",
                }}
              ></div>
            ) : null}
          </div>
        ))}
      </List>

      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <img alt="phocus" src={phocus} />
      </div>
    </Box>
  );
}
