import React, { createContext, ReactNode, useState, useEffect } from "react";

export const context = createContext({ lock: true, setLock: (v: boolean | ((v: boolean) => boolean)) => {} });

export default function LockProvider({ controlledLock, children }: { controlledLock?: boolean; children: ReactNode }) {
  const [lock, setLock] = useState(controlledLock !== undefined ? controlledLock : true);

  useEffect(() => {
    if (controlledLock !== undefined) {
      setLock(controlledLock);
    }
  }, [controlledLock]);

  return <context.Provider value={{ lock, setLock }}>{children}</context.Provider>;
}
