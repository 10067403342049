import React from "react";
import { Typography, Dialog, Box, DialogTitle, IconButton, useMediaQuery } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import danger from "assets/icons/Danger.svg";
import Button from "app/Button";

export default function ConfirmModal({
  open,
  onClose,
  onConfirm,
  text,
  notShowText,
  confirmButtonTitle,
  title,
}: {
  text?: string;
  open: boolean;
  onClose: () => void;
  title?: string;
  confirmButtonTitle?: string;
  onConfirm: any;
  notShowText?: boolean;
}) {
  const phone = useMediaQuery("(max-width:600px)");
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={1}
        style={{
          boxShadow: "0px 1px 3px 0px #00000026",
        }}
      >
        <Box display={"flex"} alignItems="center">
          <img loading="lazy" src={danger} alt="danger" width={23} height={23} />
          <DialogTitle sx={{ color: "#FF0000", fontWeight: 600, ml: -2 }}>{title || "Delete"}</DialogTitle>
        </Box>
        <IconButton
          title="Close"
          sx={{
            background: "#EEEFF2",
            borderRadius: 2,
            "& .MuiButtonBase-root ": {
              padding: "4px",
            },
          }}
          onClick={onClose}
        >
          <CloseRounded sx={{ width: 16, height: 16 }} />
        </IconButton>
      </Box>

      <Box m={1} p={1} maxHeight={160} height={"auto"}>
        <Typography variant={phone ? "body1" : "h6"}>
          {notShowText ? `${text}` : `Are you sure you want to delete ${text?.toLowerCase()}?`}
        </Typography>

        <Box mt={3} mb={2} display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            style={{ width: 100, marginRight: 4, background: "#EEEFF2", color: "#202831" }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button style={{ width: 100 }} color="primary" kind="delete" onClick={onConfirm}>
            {confirmButtonTitle || "Delete"}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
