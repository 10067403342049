import React from "react";
import { Button, ButtonProps, Tooltip } from "@mui/material";
// import { AddRounded, EditRounded, DeleteRounded } from "@mui/icons-material";
import { Gradients } from "../theme";

interface IButton extends ButtonProps {
  kind?: "add" | "edit" | "delete";
  disableText?: any;
  dialogMode?: boolean;
  bolder?: boolean;
}
// let icons = {
//   add: <AddRounded />,
//   edit: <EditRounded />,
//   delete: <DeleteRounded />,
// };
export default function MyButton({ bolder, disableText, dialogMode, kind, ...props }: IButton) {
  return (
    <Tooltip
      title={props.disabled && !dialogMode ? "Please unlock first" : props.disabled && dialogMode ? disableText : ""}
      placement="right"
      sx={{ zIndex: 9999 }}
    >
      <Button
        sx={{
          width: 150,
          background: kind
            ? kind === "add"
              ? Gradients.success
              : kind === "edit"
              ? Gradients.warning
              : Gradients.error
            : "default",
          borderRadius: "0.5em",
          boxShadow: "none",
          paddingRight: { xs: "25px", md: "10px" },
          paddingLeft: { xs: "25px", md: "10px" },
          "&:disabled": {
            color: "rgba(0, 0, 0, 0.5) !important",
          },
          "&:hover": {
            border: bolder ? "2px solid #E68031" : "",
            fontWeight: bolder ? "bold" : "",
            background: kind
              ? kind === "add"
                ? Gradients.success
                : kind === "edit"
                ? Gradients.warning
                : Gradients.error
              : "default",
          },
          border: bolder ? "2px solid #E68031" : "",
          fontWeight: bolder ? "bold" : "",
        }}
        // startIcon={!phone ? (kind ? icons[kind] : props.startIcon) : null}
        variant={kind ? "contained" : props.variant}
        {...props}
      >
        {props.children}
      </Button>
    </Tooltip>
  );
}
