import React, { useState } from "react";
import { useHistory } from "react-router";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import QRScanner from "app/QRScanner";
import scan from "assets/icons/header/Scan.svg";
import { QR } from "logic/QR";

export default function UPCToggle() {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleScan = async (data: string) => {
    try {
      const qr = new QR("device");
      const dataBody = qr.extract(data);
      console.log({ dataBody });

      if (dataBody?.queries._qr_type === "item") {
        history.push(`/panel/${dataBody?.queries.panel}/items/${dataBody?.queries.itemId}`);
        return;
      }

      if (dataBody?.queries._qr_type === "unit") {
        history.push(`/panel/engineering/device/units/${dataBody?.queries.unitId}`);
        return;
      }

      if (dataBody?.queries._qr_type === "so") {
        history.push(`/panel/sales/salesOrders/${dataBody?.queries.soId}`);
        return;
      }

      if (dataBody?.queries._qr_type === "quote") {
        history.push(`/panel/sales/quote/quotes/${dataBody?.queries.quoteId}`);
        return;
      }

      if (dataBody?.queries._qr_type === "po") {
        history.push(`/panel/purchase/purchaseOrder/${dataBody?.queries.poId}`);
        return;
      }

      if (dataBody?.queries._qr_type === "receive") {
        history.push(`/panel/inventory/receiving/${dataBody?.queries.id}`);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <IconButton size="small" title="UPC" onClick={handleClick} color="inherit">
        <img alt="scan" src={scan} style={{ width: 28, height: 28 }} />
      </IconButton>
      <Menu
        id="QR Scanner"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <QRScanner
          sx={{ p: 1, width: 270, height: 300 }}
          onResult={(r) => r && handleScan(r)}
          width={250}
          height={250}
        />
      </Menu>
    </>
  );
}
