import { addQuarters, eachQuarterOfInterval, endOfYear, format, startOfYear, set } from "date-fns";

export function formatSeconds(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;
  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
}

export const formatTimestampToDate = (timestamp?: number | string | null | Date) => {
  if (!timestamp || String(timestamp).includes("P")) {
    return "";
  }
  if (typeof timestamp === "string" || typeof timestamp === "number") {
    return format(new Date(timestamp), "MM-dd-yyyy");
  }
  return format(timestamp, "MM-dd-yyyy");
};

export const formatTimesToHour = (timestamp?: number) => {
  return timestamp ? format(timestamp, "MM-dd-yyyy, hh:mm aaa") : "";
};

export function formatDateValue(v: any) {
  if (v) {
    return new Date(v).toISOString().slice(0, 10);
  }
  return null;
}

export function getYearQuarters(data = Number(new Date())) {
  const start = startOfYear(data);
  const end = endOfYear(data);

  const quarters = eachQuarterOfInterval({
    start,
    end,
  });
  const nextQuarter = addQuarters(quarters[quarters.length - 1], 1);

  return [...quarters, nextQuarter];
}

export function setYear(date?: number, year?: number) {
  if (!date) {
    return undefined;
  }
  if (!year) {
    return date;
  }
  return Number(set(date, { year }));
}

export function getYearInterval(year: number) {
  const d = Number(set(new Date(), { year }));
  return { start: Number(startOfYear(d)), end: Number(endOfYear(d)) };
}
