import { createTheme } from "@mui/material";

export const Gradients = {
  warning: "linear-gradient(60deg, #ffa726, #fb8c00)",
  error: "linear-gradient(60deg, #ef5350, #e53935)",
  success: "#44B678",
  info: "linear-gradient(60deg, #26c6da, #00acc1)",
};

export const theme = createTheme({
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 2,
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: "primary",
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 10,
          fontWeight: "bold",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: "textSecondary",
      },
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            // height: 32,
          },
        },
      },
      defaultProps: {
        size: "small",
        variant: "outlined",
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: "small",
      },
    },
  },
  palette: {
    divider: "#4f4f4f",
    primary: {
      // main: "#3389ff",
      main: "#e68031",
      contrastText: "#fff",
    },
    secondary: {
      main: "#373a4d",
      contrastText: "#fff",
    },
    warning: {
      main: "#ffdb2e",
      contrastText: "#545454",
    },
    error: {
      main: "#ff0000",
      contrastText: "#ff0000",
    },
    success: {
      dark: "#4da851",
      contrastText: "#fff",
      main: "rgba(103,214,73,1)",
    },
    info: {
      main: "#ebefff",
      contrastText: "#545454",
    },
    background: {
      default: "#f9f9f9",
    },
  },
});
