import React from "react";
import { Toolbar, IconButton, AppBar } from "@mui/material";
import { TopAppBar } from "app/TopAppBar";
import { AppBarStation } from "logic/PortalContext/Stations";
import { drawerWidth, chatDrawerWidth } from ".";
import menu from "assets/icons/header/Menu.svg";

export default function MainAppBar({
  chatDrawerOpen,
  mainDrawerOpen,
  closeChatDrawer,
  closeMainDrawer,
  openChatDrawer,
  openMainDrawer,
}: {
  mainDrawerOpen: boolean;
  chatDrawerOpen: boolean;
  openMainDrawer: () => void;
  closeMainDrawer: () => void;
  openChatDrawer: () => void;
  closeChatDrawer: () => void;
}) {
  return (
    <AppBar
      position="fixed"
      sx={{
        transition: "all 300ms ease-in-out",
        height: "60px",
        justifyContent: "center",
        BorderBottom: "1px solid gray",
        backgroundColor: "#202831",
        color: "white",
        padding: "0 !important",
        borderRadius: "0px !important",
        ...(mainDrawerOpen && {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
        }),
        ...(chatDrawerOpen && {
          width: `calc(100% - ${chatDrawerWidth}px)`,
          marginRight: `${chatDrawerWidth}px`,
        }),
      }}
    >
      <Toolbar>
        <IconButton
          id="open-drawer-button"
          color="inherit"
          aria-label="open drawer"
          onClick={openMainDrawer}
          edge="start"
          sx={{ ml: 2, ...(mainDrawerOpen && { display: "none" }) }}
        >
          <img src={menu} alt="menu" width={28} height={28} />
        </IconButton>

        <AppBarStation />
        <TopAppBar
          isChatOpen={chatDrawerOpen}
          onOpenChatClicked={() => {
            openChatDrawer();
            closeMainDrawer();
          }}
        />
      </Toolbar>
    </AppBar>
  );
}
